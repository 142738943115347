







































































import { FCButton, FCSelect } from '@fc/angie-ui';

import {
  computed,
  defineComponent,
  ref,
  PropType,
} from '@vue/composition-api';
import { IProduct } from '~/types/fightcamp';
import FCImage from '~/components/FCImage.vue';
import { useModal } from '~/composables/useModal';

interface AccessoryCardProduct extends IProduct {
  tag?: string;
  slug?: string;
  types?: string[];
}

export default defineComponent({
  name: 'AccessoryCard',
  emits: ['itemAdded'],
  components: {
    FCImage,
    FCButton,
    FCSelect,
  },
  props: {
    product: {
      type: Object as PropType<AccessoryCardProduct>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const productId = computed(() => (props.product.variantId ?? props.product.item_id ?? props.product.id));

    // @ts-ignore
    const { $store, $options } = useNuxtApp();

    function openLightbox(item: AccessoryCardProduct) {
      const { modalShow } = useModal({
        component: () => import('~/components/FCLightBox.vue'),
        componentProps: { item },
        modalProps: {
          classes: 'modal-border-radius',
          componentName: 'FCLightBox',
        },
      });

      modalShow();
    }

    function handleSizeItem() {
      if (sizeSelected.value) {
        sizeSelectorError.value = false;
        addItemToCart(sizeSelected.value);
      } else {
        sizeSelectorError.value = true;
      }
    }

    const isShopifyOn = computed(() => $store.getters.isShopifyOn);
    function triggerAddToCart() {
      if (hasSizeOptions.value) {
        handleSizeItem();
      } else {
        const itemId = isShopifyOn.value ? props.product.variants[0].id : productId.value;
        addItemToCart(itemId);
      }
    }

    async function addItemToCart(itemId: string) {
      await $store.dispatch('insertItemToCart', {
        id: itemId,
        type: 'equip',
        disableDrawer: true,
        delay: true,
      });

      $store.commit('ITEM_ADDED');
      emit('itemAdded');
    }
    const sizeSelected = ref(null);
    const hasSizeOptions = computed(() => props.product?.selects?.options.length);
    const sizeSelectorError = ref(false);

    function getRelativeUrl(url: string | undefined) {
      return url?.substring(url.indexOf('/v4'));
    }

    const getProvider = computed(() => {
      const availableImg = props.product?.img_url || props.product?.marketplace_img_url;

      return isStoryblokImage(availableImg) ? 'storyblok' : 'fcProvider';
    });

    function isStoryblokImage(url: string | undefined) {
      return url?.includes('a.storyblok.com');
    }

    const getFilters = computed(() => (props.product?.types?.includes('Apparel') ? { focal: '450x0:550x100' } : {}));

    const getSrc = computed(() => props.product?.marketplace_img_url || getRelativeUrl(props.product?.img_url) || props.product?.imgUrl);

    const getTheme = computed(() => (props.product?.sold_out ? 'button-disabled' : 'button-primary'));
    const getCopy = computed(() => (props.product?.sold_out ? 'Sold Out' : 'Add to Cart'));

    const price = computed(() => $options.filters.currency2(props?.product?.price || props?.product?.product?.price));
    const oldPrice = computed(() => $options.filters.currency2(props?.product?.oldPrice));
    const showOldPrice = computed(() => oldPrice.value > price.value);

    /* there is a lot of prop drilling going on here. This is a temporary solution
    until we can refactor the product card and maybe use provide/inject
    https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity */

    const productTag = computed(() => props?.product?.tag ?? '');
    const productSlug = computed(() => props?.product?.slug ?? '');
    const productTitle = computed(() => props?.product?.title ?? '');
    const prodSelectHint = computed(() => props?.product?.selects?.hint ?? '');
    const prodSelectOptions = computed(() => props?.product?.selects?.options ?? []);

    return {
      productId,
      openLightbox,
      triggerAddToCart,
      hasSizeOptions,
      sizeSelected,
      sizeSelectorError,
      addItemToCart,
      showTag: computed(() => props.product.tag),
      imageWrapper: computed(() => (props.product.slug ? 'NuxtLink' : 'button')),
      getRelativeUrl,
      getProvider,
      getFilters,
      getSrc,
      getTheme,
      getCopy,
      price,
      oldPrice,
      showOldPrice,
      productTag,
      productSlug,
      productTitle,
      prodSelectHint,
      prodSelectOptions,
    };
  },
});
