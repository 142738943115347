var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"track-view",rawName:"v-track-view",value:(("AccessoryCard_" + _vm.productId)),expression:"`AccessoryCard_${productId}`"}],staticClass:"accessory-card fc-flex fc-column fc-col-span-2 fc-margin-bottom-9 md:fc-col-span-3"},[(_vm.showTag)?_c('div',{staticClass:"accessory-card__tag fc-text-white fc-padding-y-1 fc-padding-x-2 fc-bg-blue"},[_c('p',{staticClass:"fc-font-size-4 md:fc-font-size-4 fc-padding-0 fc-margin-0"},[_vm._v("\n      "+_vm._s(_vm.productTag)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"accessory-card__image fc-flex fc-align-center fc-justify-center fc-column fc-bg-white"},[_c(_vm.imageWrapper,{tag:"component",staticClass:"accessory-card__image-wrapper fc-margin-0 fc-padding-0 fc-bg-white",attrs:{"to":("/" + _vm.productSlug)},on:{"click":function($event){return _vm.openLightbox(_vm.product)}}},[(_vm.getSrc)?_c('FCImage',{attrs:{"fc-provider":_vm.getProvider,"fc-modifiers":{
          filters: _vm.getFilters,
        },"nuxt-image-props":{
          src: _vm.getSrc,
          alt: _vm.productTitle,
          sizes: 'lg:400px',
          width: '200',
        },"display-type":"cover"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"accessory-card__info fc-text-left md:fc-flex md:fc-justify-between md:fc-align-center md:fc-margin-y-4"},[(_vm.productSlug)?_c('NuxtLink',{staticClass:"accessory-card__title fc-text fc-text-blue fc-padding-y-2 fc-margin-0 fc-padding-0 fc-text-left fc-underline",attrs:{"to":("/" + _vm.productSlug)}},[_vm._v("\n      "+_vm._s(_vm.productTitle)+"\n    ")]):_c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:(("AccessoryCard_OpenModal_" + _vm.productId)),expression:"`AccessoryCard_OpenModal_${productId}`"}],staticClass:"accessory-card__title fc-text fc-text-blue fc-padding-y-2 fc-margin-0 fc-padding-0 fc-text-left fc-underline",attrs:{"type":"button"},on:{"click":function($event){return _vm.openLightbox(_vm.product)}}},[_vm._v("\n      "+_vm._s(_vm.productTitle)+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"accessory-card__price fc-bold fc-text-blue fc-margin-y-2 md:fc-inline-block"},[(_vm.showOldPrice)?_c('span',[_c('s',[_vm._v(_vm._s(_vm.oldPrice))])]):_vm._e(),_vm._v("\n      "+_vm._s(_vm.price)+"\n    ")])],1),_vm._v(" "),(_vm.hasSizeOptions)?_c('FCSelect',{staticClass:"fc-margin-y-3",attrs:{"label":_vm.prodSelectHint,"options":_vm.prodSelectOptions,"error":_vm.sizeSelectorError,"required":""},on:{"click":function($event){_vm.sizeSelectorError=false}},model:{value:(_vm.sizeSelected),callback:function ($$v) {_vm.sizeSelected=$$v},expression:"sizeSelected"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"accessory-card__button-container"},[_c('FCButton',{directives:[{name:"track-click",rawName:"v-track-click",value:(("AccessoryCard_AddCart_" + _vm.productId)),expression:"`AccessoryCard_AddCart_${productId}`"}],attrs:{"custom-event-name":"addItem","button-theme":_vm.getTheme},on:{"addItem":function($event){return _vm.triggerAddToCart()}}},[_vm._v("\n      "+_vm._s(_vm.getCopy)+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }